import logoLink from '../resources/bomag.png';
import companyLogoLink from '../resources/bomag.png';
import footerLogoLink from '../resources/bomag_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#FFDC43',
    panel_highlight: '#4d4d4d',
    chat_background_right: '#FFEDAE',
    chat_background_left: '#D5D5D5',

    active: '#FFDC43',
    inactive: '#000',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#FFEDAE',

    phonebook_dropdown: '#FFEDAE',
    phonebook_dropdown_svg: '#000',

    primary_light: '#FFEDAE',
    primary: '#FFDC43',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#000',
    text_on_secondary: 'white',

    red: '#AF2422',
    green: '3AC13A',

    connecting: '#FFDC43',
    connected: '#3AC13A',
    aborted: '#AF2422',

    font_color: 'black',
    link_color: '#4d4d4d',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#e2e2e2',

    gridbox_header_bg: '#333',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#FFDC43',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Bomag';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
